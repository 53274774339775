import React, { useState } from "react";
import Card from "../UI/Card/Card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../UI/Button/Button";
import { Navigate } from "react-router-dom";

const ContactForm = () => {
  const [submitFormEnabled, setSubmitFormEnabled] = useState(true)
  const [submitFormSuccess, setSubmitFormSuccess] = useState(false) 

  const intialInput = {
    name: "",
    email: "",
    message: "",
  };

  const [userInput, setUserInput] = useState(intialInput);

  const inputChangeHandler = (input, value) => {
    setUserInput((prevState) => {
      return { ...prevState, [input]: value };
    });
  };

  const handleServerResponse = (ok, msg, form) => {
    if (ok) {
      toast("Tack för ditt meddelande!");
      setUserInput(intialInput);
    } else {
      toast("Det där fungerade inte!");
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setSubmitFormEnabled(false);
    toast("Skickar ditt meddelande!");

    const form = e.target;

    let data = {
      Name: userInput["name"],
      Email: userInput["email"],
      Message: userInput["message"],
      Token: "recaptchaToken",
    };
    fetch(process.env.REACT_APP_EMAILAPI, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
    .then((data) => {
      handleServerResponse(data.ok, data.statusText, form);
      setSubmitFormEnabled(true);
      setSubmitFormSuccess(true);
    })
    .catch((r) => {
      handleServerResponse(false, r, form);
    });
  };

  return (
    <section className="contactform">
      <Card>
        <div className="container">
          <div className="">
            <h1>
              Kontakta oss via formulär
            </h1>
            <form onSubmit={handleOnSubmit} autoComplete="on">
              <div className="">
                <label className="form-label" htmlFor="Name">
                  Namn
                  <br />
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="Name"
                  autoComplete="on"
                  required
                  value={userInput["name"]}
                  onChange={(event) =>
                    inputChangeHandler("name", event.target.value)
                  }
                />
              </div>
              <p />
              <div className="">
                <label className="form-label" htmlFor="Email">
                  E-post
                  <br />
                </label>
                <input
                  className="form-control"
                  type="email"
                  id="Email"
                  autoComplete="on"
                  required
                  value={userInput["email"]}
                  onChange={(event) =>
                    inputChangeHandler("email", event.target.value)
                  }
                />
              </div>
              <p />
              <div className="">
                <label className="form-label" htmlFor="Message">
                  Meddelande
                  <br />
                </label>
                <textarea
                  className="form-control"
                  rows="5"
                  id="Message"
                  autoComplete="off"
                  required
                  value={userInput["message"]}
                  onChange={(event) =>
                    inputChangeHandler("message", event.target.value)
                  }
                />
              </div>
              <div>
                {
                  submitFormEnabled 
                  ? <Button
                      className="button-full-width"
                      type="submit">
                      Skicka
                    </Button>
                  :
                    <div/>
                }
              </div>
            </form>
            <ToastContainer />
            {submitFormSuccess && <Navigate to="/thankyou" state="fromcontactform" replace={true} />}
          </div>
        </div>
      </Card>
    </section>
  );
};

export default ContactForm;
