import React from 'react'

const SectionEmpty = (props) => {
  return (
    <section className="sectionempty">
      <div className={`${props.style}`}></div>
    </section>
  );
};

export default SectionEmpty