import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/global/Navbar/Navbar";
import Footer from "../components/global/Footer/Footer";

const Layout = () => {
  return (
    <Fragment>
      <Navbar />
      <main className="wrapper">
        <Outlet />
        <div></div>
        <Footer />
      </main>
    </Fragment>
  );
};

export default Layout;
