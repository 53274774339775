const LinkButton = (props) => {
  return (
    <a href={props.href}>
      <button
        type={props.type}
        className={`${props.className}`}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    </a>
  );
};

export default LinkButton;
