import React, { Fragment } from "react";
//import QuoteSlider from "../components/QuoteSlider/QuoteSlider"
//import SectionImageLeft from "../components/sections/SectionImageLeft/SectionImageLeft"
import Career from "../components/Career/Career";

//import { careersData } from "../data/careersData";
//import { careerQuoteData } from "../data/careerQuoteData"
import { careerData } from "../data/careerData";

const Careers = () => {
  return (
    <Fragment>
      {/* <SectionImageLeft {...careersData[0]}/>
      <QuoteSlider data={careerQuoteData} /> */}
      <Career {...careerData[0]}/>
    </Fragment>
  );
};

export default Careers;
