import Cloudium from '../../../assets/images/logos/Cloudium 1-143x39.png';
import { Fragment } from 'react';

const Logo = () => {
  return (
    <div>
      <Fragment>
        <a href="/" className="brand-name">
          <img src={Cloudium} alt="Cloudium" />
        </a>
      </Fragment>
    </div>
  )
}

export default Logo