export const partnerData = [
    {
        id: "0",
        image: require("../assets/images/partners/MicrosoftPartner.png"),
        title: "Microsoft Partner",
        text: ""
    },
    {
        id: "1",
        image: require("../assets/images/partners/MicrosoftPartnerBlackBackground.png"),
        title: "Microsoft Partner",
        text: "Vi är stolta över vårt partnerskap med Microsoft och strävar efter att leverera enastående tjänster och support till våra kunder med hjälp av den senaste Microsoft-teknologin."
    }
];