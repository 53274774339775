import { Fragment } from "react";
import MenuItem from "./MenuItem";
import LinkButton from "../../UI/LinkButton/LinkButton";

const Menu = () => {

  return (
    <Fragment>
      <div className="navigation-menu">
        <div className="hamburger">
          <label className="hamburger-menu">
            <input type="checkbox" name="checkbox-mobile-menu"/>
          </label>
          <aside className="sidebar">
            <nav>
              <ul>
                <hr />
                <MenuItem name="Tjänster" href="/services" submenu="dropdown" menutype="mobilemenu"/>
                <hr />
                <MenuItem name="Om Cloudium" href="/aboutus" menutype="mobilemenu"/>
                <hr />
                <MenuItem name="Karriär" href="/career" menutype="mobilemenu"/>
                <hr />
              </ul>
              <div className="sidebar-menu-button">
                <LinkButton
                    className="sidebar-button"
                    type="button"
                    href="/contact"
                  >
                    Kontakta oss
                </LinkButton>
              </div>
            </nav>
          </aside>
        </div>
        <div className="top-menu">
          <ul>
            <MenuItem name="Tjänster" href="/services" submenu="dropdown" menutype="desktopmenu"/>
            <MenuItem name="Om Cloudium" href="/aboutus"  menutype="desktopmenu"/>
            <MenuItem name="Karriär" href="/career" menutype="desktopmenu"/>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Menu;
