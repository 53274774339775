export const availableWorksData = [
    {
        id: "0",
        image: require("../assets/images/Work/Work1.jpg"),
        page: "azure_cloud_architect",
        title: "Cloudium AB söker ledande Azure Cloud Architect",
        application: "Urval sker löpande",
        location: "Stockholm",
        type: "Hybrid",
        text: (
            <div>
                <h2>
                    Om oss:
                </h2>
                <p>
                    Cloudium AB är en startup inom molnteknologi och söker nu en erfaren Azure Cloud Architect för att stärka vårt team. 
                    Vi är dedikerade till att skapa och implementera stabila, kostnadseffektiva molnlösningar och driver företag framåt genom att fullt ut nyttja det Azure-plattformen har att erbjuda.
                    Vårt arbete spänner över ett brett spektrum: från att ta helhetsansvar för mindre företags kompletta molninfrastruktur till att fylla specialistroller som Cloud Architect eller Trusted Advisor hos omfattande globala organisationer.
                </p>
                <h2>
                    Dina ansvarsområden:
                </h2>
                <ul>
                    <li>
                        Leda våra kunder genom hela deras molnresa, från teoretisk design till praktisk implementering.
                    </li>
                    <li>
                        Ansvara för etablering av Azure-design, governance och implementering av infrastrukturdesign. 
                    </li>
                    <li>
                        Erbjuda innovativa och kreativa lösningar för våra kunder. 
                    </li>
                    <li>
                        Delta i olika projekt, inklusive Enterprise Scale Design, Azure-lösning workshops, IaC-implementeringar/migreringar och automatiseringsoptimering av molnmiljöer. 
                    </li>
                </ul>
                <h2>
                    Krav:
                </h2>
                <ul>
                    <li>
                        Minst 7 års erfarenhet inom IT-branschen. 
                    </li>
                    <li>
                        Praktisk hands-on erfarenhet av arbete med infrastruktur, skriptning, automatisering och/eller utveckling. 
                    </li>
                    <li>
                        Starka presentation- och kommunikationsfärdigheter. 
                    </li>
                    <li>
                        Teamspelare som trivs med att arbeta på flera projekt med olika kunder. 
                    </li>
                </ul>
                <h2>
                    Meriterande färdigheter:
                </h2>
                <ul>
                    <li>
                        Erfarenhet av försäljning. Möjlighet finns till att medverka i försäljning. 
                    </li>
                    <li>
                        Kompetens inom Utveckling och Azure DevOps.
                    </li>
                    <li>
                        Erfarenhet av migrering av SQL-databaser och virtuella maskiner till Azure. 
                    </li>
                    <li>
                        Certifieringar som Azure Solution Architect, Azure Security Engineer. 
                    </li>
                </ul>
                <h2>
                    Varför Cloudium AB:
                </h2>
                <ul>
                    <li>
                        Arbeta och ha kul med de bästa personerna inom molnbranschen. 
                    </li>
                    <li>
                        Möjlighet att påverka den europeiska IT-branschen. 
                    </li>
                    <li>
                        Flexibla arbetsmöjligheter och möjlighet till fjärrarbete. 
                    </li>
                    <li>
                        Individuell utbildningsbudget och examensavgifter för partnercertifieringar. 
                    </li>
                </ul>
                <h2>
                    Hur du ansöker:
                </h2>    
                <p>
                    Vi ser fram emot att välkomna en passionerad Azure Cloud Architect till vårt växande team på Cloudium AB!
                    Skicka ditt CV och ett kort meddelande om varför du är lämplig för rollen till: 
                </p>
            </div>
          ),
        contactimage: require("../assets/images/photos/Michael.jpg"),
        contactname: (
                <div>
                    <p>Michael Lagerberg - CEO</p>
                </div>
            ),
        contactmail: "michael.lagerberg@cloudium.se"
    }
];