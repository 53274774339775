export const servicesData = [
    {
        id: "0",
        herosectionimage: require("../assets/images/Work/landingzone.jpg"),
        page: "alzaas",
        herosectsectionstyle: "style-2",
        herosectiontitle: "Landing Zone as a Service",
        herosectiontext: "Transformera ditt företag med våra Azure-baserade lösningar. Från smidiga migreringar till robust infrastrukturhantering, ALZAAS erbjuder skräddarsydda tjänster för att förbättra prestanda, skalbarhet och säkerhet. Utnyttja vår expertis för att driva innovation och nå dina mål med självförtroende.",
        herosectionbuttonhref: "/contact",
        herosectionbuttontext: "Kontakta oss",
        section1style: "style-2",
        section1title: "Förvandla din molninfrastruktur med precision och expertis",
        section1text: "Med Azure Landing Zone som tjänst får du en skräddarsydd lösning för att bygga upp och skala din molninfrastruktur på Azure. Denna tjänst ger en omfattande grund för ditt molnäventyr, med fokus på tre huvudområden: automatiserad distribution via Infrastructure as Code (IaC), skräddarsydd säkerhet från start, och djupgående övervakning för optimal prestanda och kostnadseffektivitet.",
        section1image: require("../assets/images/selected/isolated-3168537_640.png"),
        section2style: "style-1",
        section2title: "Vad du får",
        section2titlecol1: "Automatiserad och Repeterbar Deployment",
        section2textcol1: "Genom användning av IaC erbjuder vi en snabb, konsekvent och felfri utrullning av din molninfrastruktur, vilket möjliggör enkel skalbarhet och anpassning.",
        section2titlecol2: "Förstärkt Säkerhet och Compliance",
        section2textcol2: "Vi skräddarsyr säkerheten i din miljö för att matcha dina specifika behov, samtidigt som vi följer bästa praxis, regulatoriska krav och dina interna policies. Detta innebär en anpassad säkerhetslösning som inte bara skyddar dina data och applikationer effektivt, utan också ger en säkerhetsarkitektur som är både flexibel och framtidssäker.",
        section2titlecol3: "Insikter och Optimering",
        section2textcol3: "Dra nytta av avancerad övervakning som ger realtidsinsikter i din molnprestanda, med verktyg för att aktivt hantera och optimera resursanvändning och kostnader.",
        section3style: "style-2",
        section3title: "Varför Välja Azure Landing Zone som Tjänst?",
        section3text: "Vårt erbjudande är inte bara en tjänst; det är en partnerskap där vi arbetar nära dig för att säkerställa att din molninfrastruktur inte bara uppfyller dagens behov utan är redo att växa och anpassa sig till morgondagens utmaningar. Med expertis inom Azure och en dedikerad inställning till dina framgångar, sätter vi en ny standard för vad du kan förvänta dig av din molninfrastruktur.",
        section3image: require("../assets/images/selected/success-2081167_640.png"),
        section4style: "style-2",
        section4title: "Påbörja Din Transformation",
        section4textrow: "Ta det första steget mot en mer anpassningsbar, säker och kostnadseffektiv molninfrastruktur. Kontakta oss idag för att lära dig mer om hur Azure Landing Zone som tjänst kan lyfta din molnstrategi till nästa nivå.",
        section4image: require("../assets/images/Work/landingzonetransformation.jpg"),
        section5style: "style-1",
        textTest: (
            <div>
                <h1>
                    Azure Landing Zone som tjänst
                </h1>
                <h2>
                    Förvandla din molninfrastruktur med precision och expertis
                </h2>
                <p>
                    Med Azure Landing Zone som tjänst får du en skräddarsydd lösning för att bygga upp och skala din molninfrastruktur på Azure. Denna tjänst ger en omfattande grund för ditt molnäventyr, med fokus på tre huvudområden: automatiserad distribution via Infrastructure as Code (IaC), skräddarsydd säkerhet från start, och djupgående övervakning för optimal prestanda och kostnadseffektivitet.
                </p>
                <h2>
                    Vad du får:
                </h2>
                <ul>
                    <li>
                        <b>Automatiserad och Repeterbar Deployment: </b>Genom användning av IaC erbjuder vi en snabb, konsekvent och felfri utrullning av din molninfrastruktur, vilket möjliggör enkel skalbarhet och anpassning.
                    </li>
                    <li>
                        <b>Förstärkt Säkerhet och Compliance: </b>Vi skräddarsyr säkerheten i din miljö för att matcha dina specifika behov, samtidigt som vi följer bästa praxis, regulatoriska krav och dina interna policies. Detta innebär en anpassad säkerhetslösning som inte bara skyddar dina data och applikationer effektivt, utan också ger en säkerhetsarkitektur som är både flexibel och framtidssäker.
                    </li>
                    <li>
                        <b>Insikter och Optimering: </b>Dra nytta av avancerad övervakning som ger realtidsinsikter i din molnprestanda, med verktyg för att aktivt hantera och optimera resursanvändning och kostnader.
                    </li>
                </ul>
                <h2>
                    Varför Välja Azure Landing Zone som Tjänst?
                </h2>
                <p>
                    Vårt erbjudande är inte bara en tjänst; det är en partnerskap där vi arbetar nära dig för att säkerställa att din molninfrastruktur inte bara uppfyller dagens behov utan är redo att växa och anpassa sig till morgondagens utmaningar. Med expertis inom Azure och en dedikerad inställning till dina framgångar, sätter vi en ny standard för vad du kan förvänta dig av din molninfrastruktur.
                </p>
                <h2>
                    Påbörja Din Transformation
                </h2>
                <p>
                    Ta det första steget mot en mer anpassningsbar, säker och kostnadseffektiv molninfrastruktur. Kontakta oss idag för att lära dig mer om hur Azure Landing Zone som tjänst kan lyfta din molnstrategi till nästa nivå.
                </p>
                <p>
                    Med vänlig hälsning / Best regards,
                </p>
            </div>
          ),
        contactimage: require("../assets/images/photos/Michael.jpg"),
        contactname: "Michael Lagerberg",
        contactrole: "CEO & Trusted Advisor",
        contactmail: "michael.lagerberg@cloudium.se",
        contactphone: "+46 701694031"
    }
];