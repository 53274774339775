import React, { Fragment } from 'react'


const Search = () => {
  return (
    <Fragment>
      <div>Search</div>
    </Fragment>
  )
}

export default Search