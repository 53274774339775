import React, { useState, useEffect } from "react";
import Logo from "./Logo";
import Menu from "./Menu";
import LinkButton from "../../UI/LinkButton/LinkButton";

const Header = () => {
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [cssActive, setCssActive] = useState(
     () => {
      switch(window.location.pathname){
        case '/':
          return "activeTransparent";
        case '/services':
          return "activeTransparentWhite";
        case '/aboutus':
          return "activeTransparent";
        case '/careers':
          return "activeTransparentWhite";
        case '/contact':
          return "activeTransparent";
        default:
          return "activeTransparentWhite";  
      }
    }
  );

  useEffect(() => {
    const controlNavbar = () => {
      if (window.scrollY < lastScrollY) {
        setShow(false); 
        if (window.scrollY <= 100){
          switch(window.location.pathname){
            case '/':
              return setCssActive("activeTransparent");
            case '/services':
              return setCssActive("activeTransparentWhite");
            case '/aboutus':
              return setCssActive("activeTransparent");
            case '/careers':
              return setCssActive("activeTransparentWhite");
            case '/contact':
              return setCssActive("activeTransparent");
            default:
              return setCssActive("activeTransparentWhite");
          }
        }
        else {
          setCssActive("active");
        } 
      } else {
        if(window.scrollY > 100){
          setShow(true);
        }
      }
      setLastScrollY(window.scrollY); 
    };
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);
  
  return (
    <section className="navbar">
      <nav className={` ${cssActive} ${show && "hidden"}`}>
        <div className="navbar-content">
          <Logo />
          <Menu />
          <div className="navbar-button">
            <LinkButton
              className="button"
              type="button"
              href="/contact"
            >
              Kontakta oss
            </LinkButton>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Header;