import React from 'react'
import LinkButton from "../../UI/LinkButton/LinkButton";

const SectionHeroWithTextAndButton = (props) => {
  return (
    <div className="sectionherowithtextandbutton">
      <div className={`${props.style}`}>
        <div className="sectionbody">
          <div className="image-top">
            <img src={props.image} alt="workimage"/>
          </div>
          <div className="body-section-background" />
          <div className="body-section">
            <div className="title-section">
              <h1>
                {props.title}
              </h1>
            </div>
            <div className="text-section">
              {props.text}
            </div>
            <div className="button-section">
              <LinkButton
                className="button"
                type="button"
                href={props.buttonhref}
              >
                {props.buttontext}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionHeroWithTextAndButton