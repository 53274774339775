import React from 'react'

const SectionImageCenterTextCenter = (props) => {
    return (
        <div className="sectionimagecentertextcenter">
            <div className={`${props.style}`}>
                <div className="sectionbody">
                    <div className="image-top">
                        <img src={props.image} alt="workimage"/>
                    </div>
                    <div className="body-background" />
                    <div className="body-section">
                        <div className="title-section">
                            <h2>
                                {props.title}
                            </h2>
                        </div>
                        <div className="text-section-row">
                            {props.textrow}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionImageCenterTextCenter