import { Fragment } from "react";

export const showcaseData = [
    {
        id: "0",
        page: "azure1",
        style: "style-1",
        image: require("../assets/images/selected/air-3807394_640.png"),
        title: "Maximera Azure-fördelarna genom vår Cloud Consulting-tjänst",
        text: "Vår Cloud Consulting-tjänst är skräddarsydd för att hjälpa dig dra full nytta av Microsoft Azure, den ledande molnplattformen för företag. Genom vår expertis och tekniska support kan vi skapa en effektiv, skalbar och säker molnmiljö som driver din verksamhet framåt."
    },
    {
        id: "1",
        page: "azure2",
        style: "style-2",
        image: require("../assets/images/selected/isolated-3168537_640.png"),
        title: "Molnstrategi och planering",
        text: "Vi utformar en anpassad molnstrategi baserad på dina affärsbehov och mål. Vi skapar en smidig övergång till Azure genom att analysera din befintliga IT-arkitektur och utveckla en tydlig roadmap."
    },
    {
        id: "2",
        page: "azure3",
        style: "style-3",
        image: require("../assets/images/selected/people-5579977_640.png"),
        title: "Arkitektur och design",
        text: "Vi ser till att du får en robust och säker infrastruktur i Azure. Vi utnyttjar kraften i Azure-tjänster som virtuella maskiner, nätverkskomponenter och säkerhetslösningar för att leverera en skalbar lösning."
    },
    {
        id: "3",
        page: "azure4",
        style: "style-1",
        image: require("../assets/images/selected/logo-3418127_640.png"),
        title: "Migrering till Azure",
        text: "Vi hjälper dig smidigt migrera dina applikationer och datacenter till Azure. Vi utvärderar befintliga system, identifierar migreringsstrategier och genomför överflyttningen säkert och effektivt."
    },
    {
        id: "4",
        page: "azure5",
        style: "style-2",
        image: require("../assets/images/selected/success-2081167_640.png"),
        title: "Optimering och kostnadshantering",
        text: "För att maximera prestanda och kostnadseffektivitet i din Azure-miljö analyserar och optimerar vi molnresurser, hanterar skalning och implementerar kostnadsoptimeringsstrategier."
    },
    {
        id: "5",
        page: "azure6",
        style: "style-3",
        image: require("../assets/images/selected/network-3482974_640.png"),
        title: "Säkerhet och efterlevnad",
        text: "Vi säkerställer att din Azure-miljö är skyddad och följer efterlevnadsregler och bästa praxis. Vi implementerar säkerhetsåtgärder och övervakning samt hjälper dig att uppfylla branschspecifika standarder."
    },
    {
        id: "6",
        page: "azure7",
        style: "style-2",
        image: require("../assets/images/selected/work-5491795-corp.png"),
        title: "Utbildning och support",
        text: "Vi erbjuder utbildning och support för att stärka din kompetens inom Azure. Vårt team finns alltid tillgängligt för att hjälpa dig på vägen."
    },
    {
        id: "7",
        page: "azure8",
        style: "SlidingShowcaseVideo",
        image: require("../assets/images/backdrop/Slide3.jpg"),
        title: "Din Microsoft Azure Partner",
        text: <Fragment><p>Vi vägleder dig genom varje steg av din molnresa. Oavsett var du är i processen, ger vi den hjälp och expertis du behöver för framgång. Från planering och implementering till optimering och support. <br /></p><p>Vi är här för att stötta dig!</p></Fragment>
    },
    {
        id: "8",
        page: "",
        style: "",
        image: require("../assets/images/backdrop/Slide2.jpg"),
        title: "",
        text: ""
    },
    {
        id: "9",
        page: "azure10",
        style: "",
        image: require("../assets/video/video1.mp4"),
        title: "Think Cloudium",
        text: "Våra Cloud Consulting-tjänster är skräddarsydda för att hjälpa dig."
    },
    {
        id: "10",
        page: "azure11",
        style: "style-2",
        image: require("../assets/images/selected/Azure-Logo-2020-present-426x426.png"),
        title: "Få full nytta av Azure med vår expertis",
        text: "Oavsett om du funderar på att migrera till Azure eller behöver expertstöd i din befintliga Azure-miljö så är vi rätt konsult för dig. Vi har skräddarsydda tjänster för alla kunder - stora som små. Vi kan hantera hela miljön åt er, vara en del av ert team eller stödja era projekt. Tack vara vår omfattande kunskap och erfarenhet inom Azure kan ni känna er trygga i att vi tar hand om era behov på bästa möjliga sätt. Lita på oss som er pålitliga partner för att nå framgång i molnet. Kontakta oss för att diskutera era behov och låt oss ta er Azure-upplevelse till nästa nivå."
    }
];