import React, { Fragment } from "react";
import SectionSlidingBackground from "../components/sections/SectionSlidingBackground/SectionSlidingBackground";
import { showcaseData } from "../data/showcaseData";
import SectionVideo from "../components/sections/SectionVideo/SectionVideo";
import SectionLeftWithButton from "../components/sections/SectionLeftWithButton/SectionLeftWithButton";

const Home = () => {
  return (
    <Fragment>
      <SectionVideo {...showcaseData[7]} />
      <SectionLeftWithButton
        {...showcaseData[10]}
        showcasebuttontext1={"Kontakta oss"}
        showcasebuttonhref1={"/contact"}
        showcasebuttontext2={"Våra tjänster"}
        showcasebuttonhref2={"/services"}
      />
      <SectionSlidingBackground />
    </Fragment>
  );
};

export default Home;
