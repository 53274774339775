import React from 'react'

const SectionImageRightTextLeft = (props) => {
  return (
    <section className="sectionimagerighttextleft">
      <div className={`${props.style}`}>
        <div className="sectionbody">
          <div className="cards">
            <div className="cardleft">
              <div>
                <h2 className="title">
                  {props.title}
                </h2>
                <div className="text">{props.text}</div>
              </div>
            </div>
            <div className={`cardright image`}>
              <img src={props.image} alt="Showcase" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionImageRightTextLeft