import React, { Fragment } from 'react'

const SectionSlidingBackground = () => {
  return (
    <Fragment>
        <section className="sectionslidingbackground">
        </section>
    </Fragment>
  )
}

export default SectionSlidingBackground
