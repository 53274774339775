import React, { Fragment } from "react";

export const careerEmployeeQuoteData = [
  {
    id: "0",
    style: "style-1",
    name: "Michael Lagerberg",
    title: "CEO",
    image: require("../assets/images/photos/Michael.jpg"),
    text: (
      <Fragment>
        <p>
          Vi är inte bara ett företag som erbjuder tekniska lösningar - vi är en
          plats för passion, utveckling och möjligheter. På vårt företag handlar
          det inte bara om att driva molnbaserade projekt, utan också om att
          odla talanger och uppmuntra till personlig tillväxt.
        </p>
        <p>
          Som grundare brinner jag själv för teknik och dess förmåga att
          förändra världen. Men det som verkligen driver mig är att kunna ge
          förutsättningar för andra att hitta och uppnå sin fulla potential.
          Vårt team består av individer som delar denna passion och vi tror på
          att skapa en arbetsmiljö där varje medarbetare kan blomstra och
          utvecklas.
        </p>
        <p>
          Vi strävar efter att vara en plattform där du kan ta din karriär till
          nya höjder, oavsett om du är ny inom Cloud-teknologi eller en erfaren
          expert. Vi tror på att ständigt lära oss och växa tillsammans.
        </p>
      </Fragment>
    ),
  },
  {
    id: "1",
    style: "style-2",
    name: "Daniel Björk",
    title: "CTO",
    image: require("../assets/images/photos/Daniel.jpg"),
    text: (
      <Fragment>
        <p>
          På Cloudium AB är vår passion att upptäcka och stötta unikt begåvade
          individer på deras resa mot storhet. Vi tror på att ge dem den bästa
          grund och de mest avancerade verktygen för att utveckla sina karriärer
          och färdigheter. Hos oss får varje individ skräddarsy sin tillväxt och
          vi erbjuder inspirerande förebilder från likasinnade som vägledning.
        </p>
        <p>
          Vi har förtroende för att varje individ kommer att bli mästare över
          sitt eget öde, där deras egen motivation och engagemang är nyckeln
          till framgång. Genom att ta kontroll över utmaningar och stötta
          varandra på vägen, skapar vi en kraftfull och gemensam framtid.
        </p>
        <p>
          Som en del av Cloudium AB är jag stolt över att se våra talangfulla
          stjärnor lysa upp horisonten och tillsammans sträva mot obegränsad
          framgång. Vi vet att framtidens stjärnor finns bland oss, och vi är
          här för att ge dem den hjälpande hand de behöver för att nå sina
          höjder.
        </p>
      </Fragment>
    ),
  },
  {
    id: "2",
    style: "style-1",
    name: "Vian",
    title: "Cloud Engineer",
    image: require("../assets/images/photos/Vian.png"),
    text: (
      <Fragment>
        <p>
          Hos Cloudium har jag privilegiet att arbeta tätt med Michael och Daniel. 
          Deras djupa kunskap och omfattande erfarenhet har varit en oerhörd 
          inspirationskälla för mig och har bidragit till min personliga och 
          professionella utveckling inom företaget.
        </p>
        <p>
          Cloudium är en arbetsplats som verkligen uppmuntrar till fortsatt lärande och personlig utveckling. 
          Jag har haft möjligheten att arbeta med en mängd olika arbetsuppgifter, 
          från säljstrategier till att bygga upp en hel miljö genom kodning. 
          Denna bredd i arbetsuppgifter har gett mig en unik kompetens och en solid grund att stå på i min roll.
        </p>
        <p>
          Det som jag verkligen uppskattar med att arbeta för Cloudium är den familjära stämningen som genomsyrar företaget. 
          Det känns som att vara en del av en större familj där alla strävar efter gemensamma mål och ständigt stöder varandra. 
          Detta skapar en positiv och motiverande arbetsmiljö där jag känner mig både utmanad och uppmuntrad att 
          presterar på min bästa nivå för Cloudium och våra kunder.
          Jag är stolt över att vara en del av Cloudium AB och ser fram emot att fortsätta bidra till vår gemensamma 
          framgång och växa tillsammans med teamet.
        </p>
      </Fragment>
    ),
  },
];
