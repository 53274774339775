import React from "react";
import LinkButton from "../../UI/LinkButton/LinkButton";

const SectionLeftWithButton = (props) => {
  return (
    <section className="sectionleftwithbutton">
      <div className={`${props.style}`}>
        <div className="sectionbody">
          <div className="cards">
            <div className={`image`}>
              <img src={props.image} alt="Showcase" />
            </div>
            <div className="">
              <h2 className="title">{props.title}</h2>
              <div className="text">{props.text}</div>
              <div className="actions">
                <LinkButton
                  className="button"
                  type="button"
                  href={props.showcasebuttonhref1}
                >
                  {props.showcasebuttontext1}
                </LinkButton>

                <LinkButton
                  className="button"
                  type="button"
                  href={props.showcasebuttonhref2}
                >
                  {props.showcasebuttontext2}
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionLeftWithButton;
