import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <div className="notfound">
      <h1>Sidan kunde inte hittas!</h1>
      <p>Gå till <Link to="/">huvudsidan</Link>.</p>
    </div>
  )
}

export default NotFound