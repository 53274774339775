import React from 'react'
//import SectionImageLeft from "../sections/SectionImageLeft/SectionImageLeft";
//import SectionImageRight from "../sections/SectionImageRight/SectionImageRight";
import SectionImageRightTextLeft from '../sections/SectionImageRightTextLeft/SectionImageRightTextLeft';
import SectionImageLeftTextRight from '../sections/SectionImageLeftTextRight/SectionImageLeftTextRight';
import { careerEmployeeQuoteData } from "../../data/careerEmployeeQuoteData";
import { availableWorksData } from '../../data/availableWorksData';

const listWorks = availableWorksData.map(work =>
  <div key={work.id}>
    {
      work.title 
      ?    
        <a href={`/career/${work.page}`}>
          <div className="available-work-link">
            <div className="available-work-title">
              {work.title}
            </div>
            <div className="available-work-flex-grow"/>
            <div className="available-work-location">
              {work.location}
            </div>
          </div>
        </a>
      :   
        <div className="work-not-available-text">
          Just nu har vi inga jobb anonser ute men vi söker ständigt efter talanger. Skicka gärna en intresse mail till info@cloudium.se.
        </div>
    }
  </div>
); 

const Career = (props) => {
  return (
      <div className="career">
     
        <div className="headSection">
          <img src={props.image} alt="HeadImage"/>
          {/* <Slogan {...props} /> */}
        </div>
        <div className="career-text">
          {props.text}
        </div>
        <div className="comment">
          <SectionImageLeftTextRight {...careerEmployeeQuoteData[0]} />
          <SectionImageRightTextLeft {...careerEmployeeQuoteData[1]} />
          <SectionImageLeftTextRight {...careerEmployeeQuoteData[2]} />
        </div>
        <div className="available-work-section">
          <div className="available-work">
            <h2>Lediga tjänster</h2>
            <div className="available-work-list">
              {listWorks}
            </div>
          </div>
        </div>
      </div>
  )
}

export default Career