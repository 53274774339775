import React from 'react'
import { useLocation, Navigate } from 'react-router-dom';
import CheckGif from '../assets/images/icons/check-mark-circle.svg';

const ThankYou = () => {
  const location = useLocation();

  return (
    location.state !== "fromcontactform"
    ?
      <div>
        <Navigate to="/" />
      </div>
    :
      <div className='thankyou'>
        <div className='thankyouimg'>
          <img src={CheckGif} alt="Check..." />
        </div>
        <h1>
          Tack! 
        </h1>
        <p>
          Vi återkommer så fort vi har behandlat kontaktförfrågan.
        </p>
        <p>
          Följ gärna oss på:
        </p>
        <div className="socialicon">
          <a href="https://www.facebook.com/cloudiumab" className="">
            <i className={`socialicon fab fa-facebook-f`}></i>
          </a>
          <a
            href="https://x.com/cloudiumab"
            className=""
          >
            <i className={`socialicon fa-brands fa-x-twitter`}></i>
          </a>
          <a
            href="https://www.instagram.com/cloudiumab"
            className=""
          >
            <i className={`socialicon fab fa-instagram`}></i>
          </a>
          <a
            href="https://www.linkedin.com/company/cloudium-ab/about/"
            className=""
          >
            <i className={`socialicon fab fa-linkedin`}></i>
          </a>
          <a
            href="https://github.com/Cloudium-AB"
            className=""
          >
            <i className={`socialicon fab fa-github`}></i>
          </a>
        </div>
      </div>
  )
}

export default ThankYou