import React from 'react'

const SectionThreeColumns = (props) => {
  return (
    <section className="sectionthreecolumns">
      <div className={`${props.style}`}>
        <div className="sectionbody">
          <div className="columns">
            <div className="columnsectiontitle">
              <h2>{props.title}</h2>
            </div>
            <div className="columnleft">
              <div className="columntext">
                <b>{props.titlecol1} </b>
                <br/>
                {props.textcol1}
              </div>
            </div>
            <div className="columnmiddle">
              <div className="columntext">
                <b>{props.titlecol2} </b>
                <br/>               
                {props.textcol2}
              </div>
            </div>
            <div className="columnright">
              <div className="columntext">
                <b>{props.titlecol3} </b>
                <br/>               
                {props.textcol3}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionThreeColumns