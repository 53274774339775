import React from "react";
import ContactInfo from "../components/Contact/ContactInfo";

const Contact = () => {
  return (
      <ContactInfo />
  );
};

export default Contact;
