import React from 'react'
import { partnerData } from '../../data/partnerData';

const listPartners = partnerData.map(partner =>
    <div key={partner.id}>
        {
            partner.text 
            ?   
                <div className="partner-card">
                    <div className="partner-text">
                        {partner.text}
                    </div>
                </div>
            :   
                <div className="partner-card">
                    <img src={partner.image} alt={partner.title}/>
                </div>
        }
    </div>
  ); 

const Partner = () => {
    return (
        <section className="partner">
            <div className="container">
                <div className="partner-list">
                    {listPartners}
                </div>
            </div>
        </section>
    )
}

export default Partner