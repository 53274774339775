import React from "react";
import Card from "../../UI/Card/Card";

const SectionText = (props) => {
  return (
    <section className="sectiontext">
      <div className={`${props.style}`}>
        <Card>
          <h1 className="title">{props.title}</h1>
          <div className="text">{props.text}</div>
        </Card>
      </div>
    </section>
  );
};

export default SectionText;
