import React from 'react'
import Video from '../../../assets/video/videoClouds.mp4'
import VideoPoster from '../../../assets/images/selected/cloudvideoheader.png'

const SectionVideo = (props) => {
    return (
        <section className="sectionvideo">
          <div className={`${props.style}`}>
            <div className="slogan">
              <div className="container">
                <div className="slogan-text"> 
                  <div className="title">{props.title}</div>
                  <div className="text">{props.text}</div>
                </div>
              </div>
              <div className="slogan-background"></div>
            </div>
            <video className="background-video" playsInline={true} autoPlay={true} loop={true} muted={true} poster={VideoPoster}>
              <source src={Video} type="video/mp4" />
            </video>
          </div>
        </section>
    )
  }

export default SectionVideo

