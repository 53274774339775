import React from 'react'
import SectionThreeColumns from "../components/sections/SectionThreeColumns/SectionThreeColumns";
import SectionHeroWithTextAndButton from "../components/sections/SectionHeroWithTextAndButton/SectionHeroWithTextAndButton";
import SectionImageCenterTextCenter from "../components/sections/SectionImageCenterTextCenter/SectionImageCenterTextCenter";
import SectionContactForm from "../components/sections/SectionContactForm/SectionContactForm";
import SectionImageRightTextLeft from '../components/sections/SectionImageRightTextLeft/SectionImageRightTextLeft';

const CloudiumService = (props) => {
  return (
    <div className="cloudiumservice">
      <SectionHeroWithTextAndButton
        style={props.herosectsectionstyle}
        title={props.herosectiontitle} 
        text={props.herosectiontext}
        image={props.herosectionimage}
        buttonhref={props.herosectionbuttonhref}
        buttontext={props.herosectionbuttontext}
      />
      <SectionImageRightTextLeft
        style={props.section1style} 
        title={props.section1title} 
        text={props.section1text}
        image={props.section1image}
      />
      <SectionThreeColumns 
        style={props.section2style}
        title={props.section2title}
        titlecol1={props.section2titlecol1}
        textcol1={props.section2textcol1}
        titlecol2={props.section2titlecol2}
        textcol2={props.section2textcol2}
        titlecol3={props.section2titlecol3}
        textcol3={props.section2textcol3}
      />
      <SectionImageRightTextLeft
        style={props.section3style} 
        title={props.section3title} 
        text={props.section3text}
        image={props.section3image}
      />
      <SectionImageCenterTextCenter
        style={props.section4style}
        title={props.section4title} 
        textrow={props.section4textrow}
        image={props.section4image}
      />
      <SectionContactForm 
        style={props.section5style}
      />
    </div>
  )
}

export default CloudiumService