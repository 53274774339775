import React from 'react'

const Work = (props) => {
  return (
    <div className="work">
        <div className="image-top">
            <img src={props.image} alt="workimage"/>
        </div>
        <div className="bodySection">
            <div className="head-section-title">
                <h1>
                    {props.title}
                </h1>
            </div>
            <div className="head-section-icon">
                <div className="icon">
                    <i class="fa fa-wpforms" aria-hidden="true" /> {props.application}
                </div>
                <div className="icon">
                    <i class="fa fa-globe" aria-hidden="true" /> {props.location} 
                </div>
                <div className="icon">
                    <i class="fa fa-clock-o" aria-hidden="true" /> {props.type}
                </div> 
            </div>
            <div className="text-section">
                {props.text}
            </div>
            <div className="contact-mail">
                <i class="fa fa-envelope-open" aria-hidden="true"></i>
                {props.contactmail}
            </div>
            <div className="apply-section">
                <div className="contact-person-image">
                    <img src={props.contactimage} alt="workimage"/>
                </div>
                <div className="contact-person">
                    {props.contactname}
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default Work