import React, { Fragment } from "react";
import SectionText from "../components/sections/SectionText/SectionText";
import Partner from "../components/Partner/Partner";

const AboutUs = () => {
  const data = {
    id: 1, 
    style: "style-6",
    title: "Om Cloudium",
    text: (
      <Fragment>
        <p>
          Vi som grundade Cloudium har dedikerat de senaste 10 åren till att
          specialisera oss inom infrastruktur, SQL Server och Azure. Med en
          sammanlagd erfarenhet på över 35 år inom IT-branschen är vi en 
          pålitlig partner som kan hjälpa dig att navigera genom den komplexa 
          världen av IT-infrastruktur och molnteknologi.
        </p>
        <p>
          Vi har en djup förståelse för de utmaningar och möjligheter som
          företag står inför när de övergår till molnet. Genom att fokusera på
          Infrastruktur, SQL Server och Azure har vi förvärvat en unik insikt
          och kompetens inom dessa områden. Vi är stolta över att kunna erbjuda
          våra kunder skräddarsydda lösningar som optimerar deras infrastruktur,
          hanterar SQL Server-miljöer och låter dem dra full nytta av fördelarna
          med Azure.
        </p>
        <p>
          Vårt mål är att erbjuda exceptionell service och leverera resultat som
          överträffar våra kunders förväntningar. Med vår gedigna erfarenhet och
          specialisering är vi redo att stödja och guida dig genom varje steg på
          din resa mot en effektiv, säker och skalbar IT-infrastruktur i molnet.
          Låt oss hjälpa dig att ta din verksamhet till nästa nivå med vår expertis.
        </p>
      </Fragment>
    ),
  };

  return (
    <Fragment>
      <SectionText {...data}/>
      <Partner/>
    </Fragment>
  );
};

export default AboutUs;
