import React, { Fragment } from "react";
import SectionEmpty from "../components/sections/SectionEmpty/SectionEmpty";
import { showcaseData } from "../data/showcaseData";
import SectionImageRightTextLeft from '../components/sections/SectionImageRightTextLeft/SectionImageRightTextLeft';
import SectionImageLeftTextRight from '../components/sections/SectionImageLeftTextRight/SectionImageLeftTextRight';

const Services = () => {
  return (
    <div className="services">
      <Fragment>
        <SectionEmpty {...showcaseData[0]} />
        <SectionImageRightTextLeft {...showcaseData[0]} />
        <SectionImageLeftTextRight {...showcaseData[1]} />
        <SectionImageRightTextLeft {...showcaseData[2]} />
        <SectionImageLeftTextRight {...showcaseData[3]} />
        <SectionImageRightTextLeft {...showcaseData[4]} />
        <SectionImageLeftTextRight {...showcaseData[5]} />
        <SectionImageRightTextLeft {...showcaseData[6]} />
      </Fragment>
    </div>
  );
};

export default Services;
