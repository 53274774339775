import React, { Fragment } from "react";
import ContactForm from "./ContactForm";

const ContactInfo = () => {
  return (
    <Fragment>
      <div className="contactinfo">
        <section className="style-5">
          <div className="content-body">
            <div className="wrapper"> 
              <div className="content-text">
                <div className="content-top">
                  <h1>Kontakta Cloudium</h1>
                  <p className="text">
                    Vi uppskattar att du vill nå oss och ser fram emot att höra
                    från dig. Vi är här för att besvara dina frågor, ta emot dina
                    synpunkter och hjälpa till på alla sätt vi kan. Tveka inte att
                    kontakta oss. <br />
                    Följ oss gärna på LinkedIn.
                  </p>
                </div>

                <div className="content-bottom">
                  <h2>Maila</h2>
                  <p className="text-1">
                    <b>Michael Lagerberg (CEO):</b>
                    <br />
                    <a href="mailto:michael.lagerberg@cloudium.se">
                      michael.lagerberg@cloudium.se
                    </a>
                  </p>
                  <p className="text-2">
                    <b>Daniel Björk (CTO):</b>
                    <br />
                    <a href="mailto:daniel.bjork@cloudium.se">
                      daniel.bjork@cloudium.se
                    </a>
                  </p>
                </div>

                <div>
                  <h2>LinkedIn</h2>
                  <p className="text">
                    Följ oss gärna på LinkedIn där vi delar med oss av tips och
                    information som du har nytta av i din roll. Att följa oss på
                    LinkedIn öppnar upp ett stort nätverk av likasinnade
                    yrkespersoner.
                    <br/>
                    <a href="https://www.linkedin.com/company/cloudium-ab/">
                      Cloudium AB @ LinkedIn
                    </a>
                  </p>
                </div>
              </div>
              <div className="contactform">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
        <section className="filler"></section>
      </div>
    </Fragment>
  );
};

export default ContactInfo;
