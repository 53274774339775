import { Fragment } from "react";
import { servicesData } from "../../../data/servicesData";

const MenuItem = (props) => {
  return (
    <Fragment>
      <div className="menuitem">
        {
          props.submenu
          ? 
            <div className="menus">
              <input id={`check${props.menutype} `} type="checkbox" name="checkbox-menu" />
              <label htmlFor={`check${props.menutype} `}>{`${props.name} `} <i className="fa fa-caret-down"></i></label>
              <div className="sub-link">
                <p className="spacing"></p> 
                <div className="dropdown-menu">
                  <ul className="submenu">
                    <li key={props.id} className="dropdown-content">
                      <a href={props.href} ><i className="fa fa-cloud" aria-hidden="true"></i>{`Våra ${props.name} `}</a>
                      {
                        servicesData.map((cloudiumservice, index) => {
                          return(
                            cloudiumservice.page 
                            ?              
                              <a key={`${index}`} href={`/Services/${cloudiumservice.page}`}><i className="fa fa-cloud" aria-hidden="true"></i>{cloudiumservice.herosectiontitle}</a>                           
                            :
                              <a key={`${index}`} href={props.href}>{props.name}</a>
                            );
                        })
                      }
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          :
            <li key={props.id}>
              <a href={props.href}>{props.name}</a>
            </li>
        }
      </div>
    </Fragment>
  );
};

export default MenuItem;


