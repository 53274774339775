import React, { Fragment } from "react";

const Integritetspolicy = () => {
  return (
    <Fragment>
      <p>Integrity Policy</p>
    </Fragment>
  );
};

export default Integritetspolicy;
